import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserProfileService } from '../../services/user-profile.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrl: './profile-details.component.scss'
})

export class ProfileDetailsComponent implements OnInit, OnDestroy {

  public userData: any;
  private unsubscribe$ = new Subject<void>();
  userId: string = '';

  constructor(
    private userProfileService: UserProfileService,
    private changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: params => {
          this.userId = params['user'] ? params['user'] : this.globalService.getUserId();
          this.userProfileService.fetchUserProfile(this.globalService.getUserId(), this.userId);
        }
      });
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.userProfileService.userProfileSubjectData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.userData = data;
        this.changeRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
