<div *ngIf="userData" class="container-fluid">
    <div class="row profile-review-details-container">
        <div class="col-4">
            <label>{{"userProfilePage.name" |cxTranslate}}</label>
            <p>{{userData.customerName}}</p>
        </div>
        <div class="col-4">
            <label>{{"userProfilePage.email" |cxTranslate}}</label>
            <p>{{userData.email}}</p>
        </div>
        <div class="col-4" *ngIf="userData.alternateEmail">
            <label>{{"userProfilePage.alternativeEmail" |cxTranslate}}</label>
            <p>{{userData.alternateEmail}}</p>
        </div>
        <div class="col-4">
            <label>{{"userProfilePage.company" |cxTranslate}}</label>
            <p>{{userData.company}}</p>
        </div>
        <div class="col-4">
            <label>{{"userProfilePage.mobilePhone" |cxTranslate}} </label>
            <p>{{userData.customerMobilePhone}}</p>
        </div>
    </div>
    <div class="row custom-mb-4">
        <div class="col-4">
            <label>{{"userProfilePage.userRoles" |cxTranslate}}</label>
        </div>
    </div>
</div>